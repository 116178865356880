import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`An `}<strong parentName="p">{`Edifice`}</strong>{` is a construct made through the process of `}<a parentName="p" {...{
        "href": "/Edification",
        "title": "Edification"
      }}>{`Edification`}</a>{`, using `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` to imbue an object with apparent sentience.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      